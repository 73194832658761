import router from '@/router';
import CryptoJS from 'crypto-js';
import { TMeetingInvite } from '@/_types/meeting/meeting-invite.type';

const MEETING_INVITE_CRYPTO_SALT = 'h7^%DLj&*$Jho28(';

export default class MeetingsHelper {

  public static getMeetingInviteUrl({ eventId, meetingId, meetingDate }: { eventId: number; meetingId: number; meetingDate: number }): string {
    if (!eventId || !meetingId) {
      return null;
    }

    const meetingInvite: TMeetingInvite = {
      eventId,
      meetingId,
      meetingDate,
    };

    const inviteKey = MeetingsHelper.generateMeetingInviteKey(meetingInvite);
    if (!inviteKey) {
      return null;
    }

    return location.origin + router.resolve({
      name: 'meeting-invite',
      params: {
        eventId: '' + eventId,
        inviteKey: inviteKey,
      }
    }).href;
  }

  public static generateMeetingInviteKey(meetingInvite: TMeetingInvite): string {
    return btoa(CryptoJS.AES.encrypt(JSON.stringify(meetingInvite), MEETING_INVITE_CRYPTO_SALT).toString());
  }

  public static parseMeetingInviteKey(inviteKey: string): TMeetingInvite {
    let meetingInvite: TMeetingInvite = null;
    try {
      const bytes = CryptoJS.AES.decrypt(atob(inviteKey), MEETING_INVITE_CRYPTO_SALT);
      meetingInvite = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      return null;
    }
    return meetingInvite;
  }

}
