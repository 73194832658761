<template>
  <!-- TODO: this component is needed for data initializations. Think what more nice can we do -->
  <div></div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'promo-head',
  components: {},
  computed: {
    ...mapState({
      eventInfo: state => state.eventStore.eventInfo,
    }),
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      authLogout: 'authStore/authLogout',
    }),
    ...mapState({
      authLoading: state => state.authStore.status === 'loading',
      // name: state => `${state.user.profile.title} ${state.user.profile.name}`
    }),
    navigationClasses() {
      return {
        'section-mainnav-event': ((this.$route.name === 'event-info') || (this.$route.name && this.$route.name.indexOf('promo-') > -1)),
        'section-mainnav-promopage': (this.$route.name && this.$route.name.indexOf('promo-') > -1),
      }
    },
  },
  data: function () {
    return {
      userAvatar: '/img/defaultAvatar.png'
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('authStore/authLogout').then(() => this.$router.push({
        name: 'auth-page',
        params: {
          forceAuthPageRoute: true
        }
      }));
    }
  }
}

</script>
