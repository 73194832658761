<template>
  <div class="component">
    <promo-head/>
    <div class="main">
      <div class="view-block" style="display: flex; width: 100%;">
<!--        <router-view></router-view>-->
        <meetings-list></meetings-list>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import promoHead from "./components/promoPage/head";
import MeetingsList from "./components/promoPage/meetings/meetings";

export default {
  name: "promo-page-calendar",
  components: {
    MeetingsList,
    promoHead,
  },
  computed: {
    ...mapState({
      contactList: state => state.contactStore.contactList.List,
    })
  },
  watch: {
    'listSource.type': {
      deep: true,
      handler() {
        this.$store.dispatch('promoStore/setContactListType', this.listSource.type);
      }
    },
  },
  created() {
    this.formData.event_id = this.$route.params.eventId;
  },
  data() {
    return {
      contactKey: null,
      open: "send",
      favoriteCompany: false,

      isShowContact: false,
      isShowContactCompany: false,
      contactDirection: 'row',
      isShowContactDocuments: false,
      isShowContactNotes: false,

      tab: '',

      external_id: null,
      goto: '',

      profileNavSelected: '',

      listSource: {
        type: "all"
      },
      formData: {
        event_id: null,
        external_id: null,
        owner_phone: "+380630682880",
      }
    };
  },
  methods: {
    showEditUser() {
      this.showEvents = false;
      this.showContacts = false;
      this.openMap = false;
      this.openEditUser = true;
    },
    setPage(val) {
      switch (val) {
        case 'events':
        case 'all':
        case 'own':
        case 'recommended':
          this.showEvents = true;
          this.showContacts = false;
          // this.openEditUser = false;
          this.itemsCount = `${this.promoPageList.length} ${this.$t('title.companies')}`;
          this.goto = 'events';
          this.openMap = false;
          break;
        case 'contacts':
          this.showContacts = true;
          this.showEvents = false;
          // this.openEditUser = false;
          this.itemsCount = `${this.contactList.length} ${this.$t('title.contacts')}`;
          this.goto = val;
          this.openMap = false;
          break;
        case 'map':
          this.showContacts = false;
          this.showEvents = false;
          // this.openEditUser = false;
          this.openMap = true;
          this.goto = val;
          break;
        default:
          if (this.promoPageList.length) {
            this.itemsCount = `${this.promoPageList.length} ${this.$t('title.companies')}`;
            this.goto = val;
          }
          break;
      }
    },
    promoChooseTab(val) {
      this.tab = val;
      this.listSource.type = val;
      this.setPage(val);
    },
    setNavSelected(val) {
      this.profileNavSelected = val
    }
  }
};
</script>

<style scoped lang="scss">

  .main {
    position: relative;

    &-edituserpromo {
      height: auto !important;
    }
  }

  .view-block {
    position: relative;
    padding: 66px 90px 7rem; // TODO: put .view-block into some new, common stylesheet
  }

  .right-indent {
    margin-right: 16px;
  }

</style>
