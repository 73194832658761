<template>
  <div class="biletum-box biletum-sharer"
       :style="{ 'top': top, 'left': left }"
       v-show="show"
  >
    <div class="soc-icons">
      <div class="soc-icon soc-icon-wechat" @click="showWechatOverlay()"></div>
      <div class="soc-icon soc-icon-fb" @click="share('facebook')"></div>
      <div class="soc-icon soc-icon-in" @click="share('linkedin')"></div>
      <div class="soc-icon soc-icon-vk" @click="share('vk')"></div>
<!--      <div class="soc-icon soc-icon-email" @click="share('email')"></div>-->
    </div>
    <div class="copier-wrap" ref="copierWrap">
      <input
          type="text"
          :value="this.url"
          readonly
          ref="sharerInput"
      />
      <span
          class="copy"
          @click="copyToClipBoard"
          ref="copierControl"
      ></span>
      <span class="success-message">{{ $t('sharer.copiedSuccessfully') }}</span>
    </div>
    <div class="biletum-sharer-wechat"
         v-if="showWechatBox"
    >
      <div class="content">
        <h4 class="title">{{ $t('sharer.wechatOverlayTitle') }}</h4>
        <div class="qr">
          <qrcode-vue
              :size="200"
              :value="url"
              level="Q"
          ></qrcode-vue>
        </div>
        <div class="actions text-center">
          <button class="close cur-p" @click="hideWechatOverlay">{{ $t('button.close') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "biletumSharer",
  components: {QrcodeVue: () => import(/* webpackPrefetch: true */ 'qrcode.vue')},
  props: {
    url: {
      type: String,
      default: window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + window.location.hash
    },
    position: {
      type: Object,
      default: function () {
        return {
          t: 0,
          l: 0
        }
      }
    },
    sharingTexts: {
      type: Object,
      default: function () {
        return {
          text: '',
          title: '',
        }
      }
    }
  },
  watch: {
    position: {
      immediate: true,
      deep: true,
      handler() {
        this.repositionPopup();
      }
    }
  },
  data() {
    return {
      show: false,
      showWechatBox: false,
      testURL: '',
      top: 0,
      left: 0,
      ignoreClickOutside: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  },
  methods: {
    showSharer() {
      this.show = true;
      this.temporaryIgnoreClickOutside();
      this.hideWechatOverlay();
    },
    hideSharer() {
      this.show = false;
      this.hideWechatOverlay();
    },
    toggleSharer() {
      this.show = !this.show;
      this.temporaryIgnoreClickOutside();
    },
    temporaryIgnoreClickOutside() {
      this.ignoreClickOutside = true;
      setTimeout(() => {
        this.ignoreClickOutside = false;
      }, 200);
    },
    clickOutside(event) {
      if (this.ignoreClickOutside) {
        return;
      }
      let isChildOfSharer = false;
      let el = event.target;
      if (!el || !el.parentNode) {
        return;
      }
      while (el.parentNode && (el.parentNode.tagName.toUpperCase() !== 'BODY')) {
        if (el.parentNode.classList.contains('biletum-sharer')) {
          isChildOfSharer = true;
          break;
        }
        el = el.parentNode;
      }
      if (!event.target.classList.contains('biletum-sharer') && (isChildOfSharer === false)) {
        this.hideSharer();
      }
    },
    repositionPopup() {
      this.top = this.position.t === 0 ? this.position.t : this.position.t + 'px';
      this.left = this.position.l === 0 ? this.position.l : this.position.l + 'px';
    },
    copyToClipBoard() {
      let linkInput = this.$refs.sharerInput;
      let copierWrap = this.$refs.copierWrap;
      linkInput.select();
      document.execCommand("copy");
      linkInput.blur();

      // visual feedback
      copierWrap.classList.add('copylink-done');
      setTimeout(function () {
        copierWrap.classList.remove('copylink-done');
      }, 7000);

      return false;
    },
    share(socNetworkName) {
      const URL = this.url;
      const SHARING_TEXTS = this.sharingTexts;
      let openSharer = function (url, w, h, windowName) {
        let pos = {
          top: (screen.height > h ? (screen.height / 3 - h / 2) : 0),
          left: Math.round(screen.width / 2 - w / 2)
        };
        let windowRef = window.open(url, windowName, 'left=' + pos.left + ',top=' + pos.top + ',width=' + w + ',height=' + h + ',personalbar=0,toolbar=0,scrollbars=1,resizable=1');
        windowRef.focus();
      };
      let prepareURL = function (socNetworkName) {
        let dict = {
          'facebook': 'https://www.facebook.com/sharer/sharer.php?u=[url]',
          //'twitter'	: 'https://twitter.com/intent/tweet?url=[url]&text=[text]&via=biletum',// TODO: в параметре via должен быть твиттер-аккаунт
          //'telegram'	: 'https://telegram.me/share/url?url=[url]',
          'vk': 'http://vk.com/share.php?url=[url]',
          'linkedin': 'https://www.linkedin.com/shareArticle?mini=true&url=[url]&title=[title]',
        };

        if (socNetworkName && Object.prototype.hasOwnProperty.call(dict, socNetworkName)) {
          return dict[socNetworkName]
            .replace('[url]', encodeURIComponent(URL))
            .replace('[text]', encodeURIComponent(SHARING_TEXTS.text))
            .replace('[title]', encodeURIComponent(SHARING_TEXTS.title));
        }
      };

      openSharer(prepareURL(socNetworkName), 400, 500, 'biletumSharingWindow');

    },
    showWechatOverlay() {
      this.showWechatBox = true;
    },
    hideWechatOverlay() {
      this.showWechatBox = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.biletum-sharer {
  opacity: 1;

  .soc-icon-wechat {
    display: block;
    background-image: url("~@/assets/images/soc/wechat.svg");
  }

  .soc-icon-fb {
    display: block;
    background-image: url("~@/assets/images/soc/facebook.svg");
  }

  .soc-icon-in {
    display: block;
    background-image: url("~@/assets/images/soc/linkedin.svg");
  }

  .soc-icon-vk {
    display: block;
    background-image: url("~@/assets/images/soc/vk.svg");
  }

  .soc-icon-email {
    display: block;
    background-image: url("~@/assets/images/soc/email.svg");
  }
}
</style>
