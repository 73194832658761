<template>
  <div class="meetings-page">
    <div class="column column-meetings-list">
      <h3 class="column-name">{{ $t('meetings.columnNames.confirmed') }}</h3>
      <mu-circular-progress
          v-if="!this.eventDays.length"
          class="demo-circular-progress"
          :size="36"></mu-circular-progress>
      <div class="meetings-date-selector">
        <span class="cur-p day"
              v-for="(dayObj, index) in eventDays"
              @click="selectDay('confirmed', dayObj)"
              :class="{'day-selected' : selected_dates.confirmed === dayObj.date_obj}"
              :key="index"
        >
          {{ dayObj.day_number }}
        </span>
      </div>
      <div class="time-slots">
        <transition-group name="time-slots-fade" tag="div">
          <div class="time-slot"
               v-for="timeSlot in timeSlots"
               :key="timeSlot.date_start"
               :data-id="timeSlot.meeting ? timeSlot.meeting.id : 'empty'"
               :class="{
               'time-slot-available' : isTimeSlotAvailable(timeSlot),
               'time-slot-unavailable': !isTimeSlotAvailable(timeSlot)
             }"
               v-show="showTimeSlot(timeSlot, 'confirmed')"
          >
            <div class="time-slot-content">
              <div class="time-range">
                <div class="from">{{ displayTime(timeSlot.date_start) }}</div>
                <div class="to">{{ displayTime(timeSlot.date_end) }}</div>
              </div>
              <div
                  class="time-slot-info"
                  v-if="isTimeSlotBusy(timeSlot) && !isTimeSlotDisabled(timeSlot)"
                  @click="messageContact(timeSlot.meeting)"
              >

                <div class="time-slot-avatar-wrap cur-p">
                  <div class="avatar"
                       v-if="timeSlot.meeting.contact.photo_url"
                       :style="{'background-image' : 'url(' + timeSlot.meeting.contact.photo_url + ')' }"
                  ></div>
                  <div class="avatar avatar-no-image"
                       v-else
                  ></div>
                </div>

                <div class="time-slot-contact cur-p">
                  <div class="title">{{ timeSlot.meeting.contact.name }} {{
                    timeSlot.meeting.contact.surname }}
                  </div>
                  <div class="subtitle">{{ timeSlot.meeting.contact.city }}<span
                      v-if="timeSlot.meeting.contact.city && timeSlot.meeting.contact.country">, </span>{{
                    timeSlot.meeting.contact.country }}
                  </div>
                  <div class="subtitle">{{ timeSlot.meeting.contact.company_name }}</div>
                  <div class="subtitle" v-if="timeSlot.meeting.contact.company_position">{{
                    timeSlot.meeting.contact.company_position }}
                  </div>
                </div>

              </div>
              <div class="time-slot-info" v-else>
                <div class="time-slot-avatar-wrap">
                  <div class="avatar" style="background-color: transparent;"></div>
                </div>
                <div class="time-slot-no-contact">
                <span
                    v-if="isTimeSlotAvailable(timeSlot)">{{ $t('meetings.available') }}</span>
                  <span
                      v-else>{{ $t('meetings.notAvailable') }}</span>
                </div>
              </div>
              <div class="time-slot-actions">
                <div class="time-slot-action time-slot-action-cancel"
                     v-if="timeSlot.meeting && !isTimeSlotDisabled(timeSlot)"
                     @click="cancelMeeting($event, timeSlot.meeting, timeSlot)"
                ></div>
                <div class="time-slot-action time-slot-action-message"
                     @click="messageContact(timeSlot.meeting)"
                     v-if="timeSlot.meeting && !isTimeSlotDisabled(timeSlot)"></div>
                <div class="time-slot-action time-slot-action-handshake"
                     @click="onHandshakeClick(timeSlot.meeting)"
                     v-if="timeSlot.meeting && !isTimeSlotDisabled(timeSlot)"></div>
                <div
                  v-if="timeSlot.meeting && !isTimeSlotDisabled(timeSlot)"
                  class="time-slot-action time-slot-action-share"
                  :title="$t('button.meetingShare')"
                  @click="onMeetingShareClick(timeSlot.meeting)"
                >
                  <icon-share-canonical></icon-share-canonical>
                  <biletumSharer
                    :url="getMeetingShareUrl(timeSlot.meeting)"
                    :position="{ t: 36, l: 16 }"
                    :ref="getMeetingSharerReferenceName(timeSlot.meeting)"
                  ></biletumSharer>
                </div>

                <div class="time-slot-action time-slot-action-markfree"
                     v-if="isTimeSlotDisabled(timeSlot)"
                     @click="markSlotAvailable(timeSlot)"></div>
                <div class="time-slot-action time-slot-action-markbusy"
                     v-if="!timeSlot.meeting && !isTimeSlotDisabled(timeSlot)"
                     @click="markSlotNotAvailable(timeSlot)"></div>
              </div>
            </div>
          </div>
        </transition-group>

      </div>

    </div>

    <!------------------------------------->

    <div class="column column-meetings-moderation">
      <h3 class="column-name">{{ $t('meetings.columnNames.unconfirmed') }}</h3>
      <div class="meetings-date-selector">
        <mu-circular-progress
            v-if="!this.eventDays.length"
            :size="36"></mu-circular-progress>
        <span class="cur-p day"
              v-for="(dayObj, index) in eventDays"
              @click="selectDay('requests', dayObj)"
              :class="{'day-selected' : selected_dates.requests === dayObj.date_obj}"
              :key="index"
        >
          {{ dayObj.day_number }}
          <span class="badge-notification" v-if="dayObj.badge_notification"></span>
        </span>
      </div>

      <div class="time-slots">
        <div class="no-meetings-in-day" v-if="isDayEmpty()">
          <span class="date"
                :style="{'color' : (!this.selected_dates['requests'] ? 'transparent' : 'inherit')}"
          >{{ $moment.utc(this.selected_dates['requests']).format('MMMM D, YYYY') }}</span>
          <span class="block msg">{{ $t('meetings.noMeetingsInDay') }}</span>
        </div>
        <transition-group name="time-slots-fade" tag="div">
          <div class="time-slot"
               v-for="(meeting, index) in meetingRequests"
               :key="meeting.id"
               :class="{
                 'time-slot-fading': meeting.isFading,
                 'time-slot-folding': meeting.isFolding
               }"
               v-show="showTimeSlot(meeting, 'requests') && meeting.status === 'unconfirmed'"
          >
            <div class="time-slot-overlay" v-if="meeting.status === 'canceled'">
              <div class="meeting-canceled">{{ $t('meetings.canceled') }}</div>
            </div>
            <div class="time-slot-content">
              <div class="time-range">
                <div class="from">{{ displayTime(meeting.date_start) }}</div>
                <div class="to">{{ displayTime(meeting.date_end, true) }}</div>
              </div>
              <div class="time-slot-info"
                   @click="messageContact(meeting)"
              >
                <div class="time-slot-avatar-wrap cur-p">
                  <div class="avatar"
                       v-if="meeting.contact.photo_url"
                       :style="{'background-image' : 'url(' + meeting.contact.photo_url + ')'}"
                  ></div>
                  <div class="avatar avatar-no-image"
                       v-else
                  ></div>
                </div>
                <div class="time-slot-contact cur-p">
                  <div class="title cur-p">{{ meeting.contact.name }} {{ meeting.contact.surname }}
                  </div>
                  <div class="subtitle">{{ meeting.contact.city }}<span
                      v-if="meeting.contact.city && meeting.contact.country">,</span> {{
                    meeting.contact.country }}
                  </div>
                  <div class="subtitle">{{ meeting.contact.company_name }}</div>
                  <div class="subtitle" v-if="meeting.contact.company_position">{{ meeting.contact.company_position }}
                  </div>
                </div>
              </div>
              <div class="time-slot-actions">
                <div class="time-slot-action time-slot-action-reject"
                     :class="{'time-slot-action-disabled' : meeting.status === 'canceled'}"
                     @click="cancelMeeting($event, meeting, null, index)"></div>
                <div class="time-slot-action time-slot-action-approve"
                     :class="{'time-slot-action-disabled' : (meeting.status !== 'unconfirmed') || (meeting.is_creator !== false)}"
                     @click="confirmMeeting($event, meeting, index)"></div>
                <div class="time-slot-action time-slot-action-fav"
                     :class="{'time-slot-action-disabled' : (meeting.is_creator !== false)}"
                     @click.stop="toggleContactFavorite(meeting.contact)">
                  <icon-feather-star
                      :fill="meeting.contact.is_favorite"></icon-feather-star>
                </div>
              </div>
              <div class="meeting-error"
                   v-if="meeting.errors && meeting.errors.length"
              >
                <div class="meeting-error-text" v-for="(error, index) in meeting.errors" :key="index">
                  {{ error.text }}
                </div>
                <div class="meeting-error-actions text-center">
                  <span class="meeting-error-action cur-p"
                        @click="clearMeetingErrors(meeting)">{{ $t('button.close') }}</span>
                </div>
              </div>
            </div>
          </div>
        </transition-group>

      </div>
    </div>

    <!--
      On load, contact from first unconfirmed meeting shows here
      On meeting click, column shows the clicked user
    -->
    <div class="column column-meetings-userinfo">
      <h3 class="column-name" v-if="$route.params.contact_id">{{ $t('meetings.columnNames.contactInfo') }}</h3>
      <router-view name="contactCard" v-if="$route.params.contact_id"></router-view>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapState } from 'vuex';
import IconFeatherStar from '@/_modules/icons/components/icon-feather-star.vue';
import IconShareCanonical from '@/_modules/icons/components/icon-share-canonical.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import biletumSharer from '@/views/components/popups/biletumSharer.vue';

export default {
  name: 'meetingsList',
  components: {
    IconFeatherStar, // Раскомментировать компонент, когда доделается добавление юзера в избранные, ТОДО есть ниже
    IconShareCanonical,
    biletumSharer,
  },
  computed: {
    ...mapState("userStore", ["user_info"]),   // used to obtain current user's id
    ...mapState("eventStore", ["eventInfo"]),
    ...mapState("meetingsStore", ["meetingsPage"]),
    ...mapState("_badgeNotificationsStore", ["badgeNotifications", "badgeNotificationsPollCount"]),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
    }),
    eventId() {
      return this.$route.params.eventId ? Number(this.$route.params.eventId) : null;
    }
  },
  watch: {
    meetingsPage: {
      deep: true,
      handler() {
        if (!this.meetingsPage || !this.meetingsPage.List) {
          return;
        }
        this.meetingsData = Object.assign({}, this.meetingsPage);
        if (this.meetingsData.List.length) {
          this.categorizeRequests();

          if (!this.firstContactOpened) {
            this.openFirstContact();
          }
        }
      }
    },
    eventInfo: {
      deep: true,
      handler() {
        // Обнуление массивов
        this.eventDays = [];
        this.timeSlots = [];
        this.getEventDays(this.eventInfo);

        if (this.meetingsData && this.meetingsData.List) {
          this.categorizeRequests();
        }
        this.createTimeSlots();

        // установка изначально выбранных дней в обеих колонках
        this.selected_dates.confirmed = this.eventDays[0].date_obj;
        this.selected_dates.requests = this.eventDays[0].date_obj;
      }
    },
    user_info: {
      deep: true,
      handler() {
        this.userInfo = this.user_info;
      }
    },
    badgeNotifications: {
      deep: true,
      handler(newVal, oldVal) {
        // See comment to the same watcher in contacts.vue
        if (this.badgeNotificationsPollCount < 2) {
          return;
        }
        if (newVal.meeting_count !== oldVal.meeting_count) {
          this.renderCalendar();
        }
      }
    },
  },
  data: () => ({
    userInfo: {},

    meetingsData: {
      Limit: 20,
      Offset: 0,
      Total: 0,
      List: [],
    },

    // Здесь храним текущую JS Date, выбранную в .meetings-date-selector
    // отдельно для обеих колонок, которые назвал:
    // confirmed, requests
    selected_dates: {
      confirmed: null,
      requests: null
    },

    // Список дней мероприятия для .meetings-date-selector
    eventDays: [],

    // Список диапазонов времени дня, заполняется в mounted/this.createTimeSlots()
    timeSlots: [],

    // Массив для подтверждённых (status == confirmed)
    confirmedRequests: [],

    // Массив для неподтверждённых (status != confirmed)
    meetingRequests: [],

    // Флаг
    firstContactOpened: false,

  }),
  created() {
    this.$store.dispatch('userStore/getUserInfo');
    this.$store.dispatch('promoStore/setContactListType', 'all');
  },
  mounted() {
    this.$store.dispatch('eventStore/event', this.$route.params.eventId);
    this.getMeetingsPageData();
  },
  methods: {

    clearMeetingErrors(meeting) {
      meeting.errors = null;
    },

    renderCalendar() {
      this.meetingsData.List = [];
      this.meetingRequests = [];
      this.$store.dispatch('userStore/getUserInfo');
      this.$store.dispatch('eventStore/event', this.$route.params.eventId);
      this.getMeetingsPageData();
    },

    /* При наличии встреч открыть в правой колонке
       * карточку контакта первой встречи со статусом unconfirmed
       * AW-338
       */
    openFirstContact() {
      let meeting;

      for (let i = 0; i < this.meetingsData.List.length; i++) {
        meeting = this.meetingsData.List[i];
        const selectedDate = this.$moment.utc(this.selected_dates.requests);
        const dateStart = this.$moment.utc(meeting.date_start);
        const isCur = this.$moment(selectedDate).isSame(dateStart, 'day');

        if (meeting.status === 'unconfirmed' && isCur) {
          this.firstContactOpened = true;
          this.messageContact(meeting);
          return;
        }
      }
    },

    /* Удаление встреч status==='canceled' из массивов
       */
    filterCanceled() {
      let filterer = (x) => x.status !== 'canceled';

      if (this.meetingsData && this.meetingsData.List)
        this.meetingsData.List = this.meetingsData.List.filter(filterer);

      this.meetingRequests = this.meetingRequests.filter(filterer);
      this.confirmedRequests = this.confirmedRequests.filter(filterer);
    },

    /* Обработчик клика по .time-slot-action-handshake
       */
    onHandshakeClick(meeting) {
      const meetingRoomConfig = {
        type: 'meeting',
        eventId: this.eventId,
        meetingId: meeting.id,
        contactId: this.contact.id,
        meetingDate: this.$moment(meeting.date_start).unix(),
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    },

    getMeetingSharerReferenceName(meeting) {
      return (meeting && meeting.id) ? `meetingInviteSharer-${meeting.id}` : null;
    },

    getMeetingShareUrl(meeting) {
      return MeetingsHelper.getMeetingInviteUrl({
        eventId: this.eventId,
        meetingId: meeting.id,
        meetingDate: this.$moment(meeting.date_start).unix(),
      });
    },

    onMeetingShareClick(meeting) {
      const sharerReferenceName = this.getMeetingSharerReferenceName(meeting);
      if (!sharerReferenceName || !this.$refs[sharerReferenceName][0]) {
        return;
      }
      this.$refs[sharerReferenceName][0].showSharer();
    },

    /* С помощью этого метода показываем плашку .no-meetings-in-day
       */
    isDayEmpty() {
      let result = true;

      if (!this.meetingRequests) {
        return true;
      }

      for (let i = 0; i < this.meetingRequests.length; i++) {
        if (this.showTimeSlot(this.meetingRequests[i], 'requests') !== false) {
          result = false;
          break;
        }
      }

      return result;
    },

    /* Проверка, должен ли слот времени быть показан при
       * текущем выбранном дне ( selected_dates[column_name] )
       */
    showTimeSlot(timeSlot, column) {
      let date_start = this.$moment.utc(timeSlot.date_start);
      let date_current_day = this.$moment.utc(this.selected_dates[column]);

      return date_start.isSame(date_current_day, 'day');
    },

    /* Обработчик клика по дню в .meeting-date-selector
       */
    selectDay(column, dayObj) {
      if (Object.prototype.hasOwnProperty.call(this.selected_dates, column)) {
        this.selected_dates[column] = dayObj.date_obj;
      }

      if (this.isDayEmpty()) {
        this.$router.push({
          name: 'promo-page-calendar',
        }).catch(() => {
        })
      } else {
        this.openFirstContact();
      }

    },

    /* Список дней мероприятия для дизайна.
       * 10 11 12 и это вкладки списка.
       */
    getEventDays(ev) {
      let date_start = this.$moment.utc(ev.date_start.split('T')[0]); // split, чтобы не учитывать часы в разнице дней
      let date_end = this.$moment.utc(ev.date_end.split('T')[0]);
      let days_diff = date_end.diff(date_start, 'days');
      let iteratedDate; // Объект JS-даты для цикла

      // Заполнение массива
      for (let i = 0; i <= days_diff; i++) {
        if (i === 0) {
          iteratedDate = date_start.toDate();
        } else {
          iteratedDate = this.$moment(iteratedDate).add(1, 'days').toDate();
        }

        this.eventDays.push({
          formatted_db_style: this.$moment(iteratedDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
          date_obj: iteratedDate,
          day_number: this.$moment(iteratedDate).format('D').padStart(2, '0'),
          badge_notification: 0
        });
      }
    },

    /* Обработчик клика по «Подтвердить встречу»
       */
    async confirmMeeting(event, meeting) {
      if (!(meeting && meeting.id)) {
        return;
      }

      // Визуалка: поиск DOM-элемента .time-slot вверх от нажатой кнопки
      let meetingElement = event.target;
      while (meetingElement.tagName.toLowerCase() !== 'body') {
        if (meetingElement.classList.contains('time-slot')) {
          break;
        }
        meetingElement = meetingElement.parentNode;
      }

      if (meetingElement.tagName.toLowerCase() === 'body') {
        return;
      }

      // Визуалка: ставим CSS-класс
      meetingElement.classList.add('time-slot-processing');

      // Заглушка
      let confirmMeetingResponse = {
        status: 999
      };

      // Попытка получить реальные данные
      confirmMeetingResponse = await this.$store.dispatch('meetingsStore/confirmMeeting', {
        event_id: this.$route.params.eventId,
        meeting_id: meeting.id
      });

      // Визуалка: убираем CSS-класс
      meetingElement.classList.remove('time-slot-processing');

      // Backend error displaying
      // If response is undefined, or response.status !== 202 or if error field is present, show:
      // — response.error as is  OR
      // — default message as is
      if (!confirmMeetingResponse || confirmMeetingResponse.status !== 202 || confirmMeetingResponse.error) {
        meeting.errors = meeting.errors || [];
        meeting.errors.push({
          text: confirmMeetingResponse.error ? confirmMeetingResponse.error : this.$t('errors.meetingGeneralError'),
        });
        return;
      }

      // AW-494 If the time slot is busy, we need to provide visual feedback to user
      let attachCheckResult = this.attachMeetingToTimeSlot(meeting, true);
      if (attachCheckResult && attachCheckResult.slotIsBusy) {
        meeting.errors = meeting.errors || [];
        meeting.errors.push({
          text: this.$t('meetings.errors.slotIsBusy')
        });
        return;
      }

      // Фронтовые действия по успеху — добавление в соотв. слот времени,
      // анимация
      if (confirmMeetingResponse && confirmMeetingResponse.status && confirmMeetingResponse.status === 202) {
        let attachResult = this.attachMeetingToTimeSlot(meeting);
        if (attachResult && attachResult.status === 'meetingAttached') {
          meeting.isFading = true;
          const that = this;
          setTimeout(function () {
            meeting.isFolding = true;
          }, 250);
          setTimeout(function () {
            meeting.status = 'confirmed';
            if (typeof meeting.eventDayIndex != 'undefined') {
              try {
                that.eventDays[meeting.eventDayIndex].badge_notification--;
              } catch (e) {
                void (e);
              }
            }
          }, 500);
        }
      }
    },

    updateBadgeNotifications() {
      // this.$store.dispatch('badgeNotificationsStore/getBadgeNotificationsCount', {eventId: this.$route.params.eventId});
      this.$store.dispatch('_badgeNotificationsStore/getBadgeNotificationsCount', Number(this.$route.params.eventId));
    },

    /* Метод, обратный методу findMeetingForTime
       * Засовывает встречу в поле meeting
       * нужного слота времени. Например, для визуального
       * фидбека про confirmMeeting.
       * @param (bool)doOnlyCheck — don't attach, just check if the slot is busy
       */
    attachMeetingToTimeSlot(meeting, doOnlyCheck = false) {
      let result = {
        slotFound: false,
        status: '',
        slot: null
      };
      let slots = this.timeSlots;
      let slot;
      for (let i = 0; i < slots.length; i++) {
        slot = slots[i];
        if (slot.date_start === meeting.date_start) {
          result.slotFound = true;
          result.slot = slot;
          if (slot.meeting) {
            result.slotIsBusy = true;
            result.status = 'failed';
          } else {
            if (!doOnlyCheck) {
              result.status = 'meetingAttached';
              slot.meeting = meeting;
            }
          }
          break;
        }
      }
      return result;
    },

    /* «Cancel meeting» click handler
       */
    async cancelMeeting(event, meeting, timeSlot, index) {
      if (!(meeting && meeting.id)) {
        return;
      }

      const initialMeetingStatus = meeting.status;

      let meetingElement = event.target;
      while (meetingElement.tagName.toLowerCase() !== 'body') {
        if (meetingElement.classList.contains('time-slot')) {
          break;
        }
        meetingElement = meetingElement.parentNode;
      }

      if (meetingElement.tagName.toLowerCase() === 'body') {
        return;
      }

      meetingElement.classList.add('time-slot-processing');

      let cancelMeetingResponse = await this.$store.dispatch('meetingsStore/cancelMeeting', {
        event_id: this.$route.params.eventId,
        meeting_id: meeting.id
      });

      meetingElement.classList.remove('time-slot-processing');

      // Backend error displaying
      // If response is undefined, or response.status !== 202 or if error field is present, show:
      // — response.error as is  OR
      // — default message as is
      if (!cancelMeetingResponse || cancelMeetingResponse.status !== 202 || cancelMeetingResponse.error) {
        meeting.errors = meeting.errors || [];
        meeting.errors.push({
          text: cancelMeetingResponse.error ? cancelMeetingResponse.error : this.$t('errors.meetingGeneralError'),
        });
        return;
      }

      if (cancelMeetingResponse.status && cancelMeetingResponse.status === 202) {
        this.updateBadgeNotifications();
        if (timeSlot) {
          timeSlot.meeting = null;
        }

        // Визуальный фидбек: удалить элемент из списка, повлиять на красный кружочек у соотв. дня
        // Нужно для удаления собственной встречи — на собственных не срабатывает updateBadgeNotifications()
        if(initialMeetingStatus === 'unconfirmed'){
          meetingElement.parentNode.removeChild(meetingElement);
        }

      }

      this.filterCanceled();
    },

    /* Принимает дату в виде строки, как она из нашей базы приходит
       * возвращает строку 00:00
       */
    displayTime(dateString, round) {
      // Do not use timezone offset for now — removing the Z
      dateString = dateString.replace('Z', '');

      // Бэкенд не разрешает подтвердить две встречи, идущие подряд (напр. 00:00—00:30 00:30—01:00)
      // Так как пересекается 00:30 и 00:30
      // Поэтому при создании встречи в contactCalendar.vue/requestMeeting отнимаем минуту
      // Здесь же при выводе добавляем минуту назад,..
      if (round) {
        return this.$moment.utc(dateString).add(1, 'minutes').format("HH:mm");
      }
      // ...или не добавляем.
      return this.$moment.utc(dateString).format("HH:mm");
    },

    /* Проверяет, есть ли встреча на этот слот времени
       */
    isTimeSlotBusy(timeSlot) {
      return !!timeSlot.meeting;
    },

    /* Признак того, что слот времени зарезервлен
       * организатором — наличие встречи, создателем которой он является
       */
    isTimeSlotDisabled(timeSlot) {
      return (timeSlot.meeting && timeSlot.meeting.contact.user.id === this.user_info.id);
    },

    /* Finds eventDay made by getEventDays()
     * for a given meeting
     * returns null || eventDay index
     */
    findEventDayForMeeting(meeting) {
      for (let dayIndex = 0; dayIndex < this.eventDays.length; dayIndex++) {
        if (this.$moment(meeting.date_start).isSame(this.$moment(this.eventDays[dayIndex].date_obj), "day")) {
          return dayIndex;
        }
      }
      return -1;
    },

    /* Заполняем массивы meetingRequests, confirmedRequests
       */
    categorizeRequests() {
      this.confirmedRequests = []; // ну, чтобы не пушить в массив повторно )
      this.meetingRequests = []; // ну, чтобы не пушить в массив повторно )
      let list = this.meetingsData.List;
      let eventDayIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i].status && list[i].status === 'unconfirmed') {
          eventDayIndex = this.findEventDayForMeeting(list[i]);
          if (eventDayIndex > -1) {
            // Не учитывать встречи, которые назначил сам — их не учитывает бэкенд
            if(list[i].is_creator !== true){
              this.eventDays[eventDayIndex].badge_notification++;
            }
          }
          // AW-392 Геннадий попросил, чтобы свои unconfirmed-встречи были видны в списке, поэтому оно вне проверки
          this.meetingRequests.unshift({
            ...list[i],
            eventDayIndex: eventDayIndex,
            errors: null
          });
          // Если создал встречу сам, то этот факт используется как признак для Available/Not Available
          /* if (list[i].is_creator === true) {
            // внутри проверки ничего нет, потому что Геннадий попросил отображать свои встречи в списке. См коммент выше.
            // здесь стоял тот this.meetingRequests.push, что выше сейчас.
            // Когда понадобится снова, расскомментируйте и пользуйтесь.
            continue;
          } */
        } else if (list[i].status === 'confirmed') {
          this.confirmedRequests.push(list[i]);
        }
      }

      // Из-за асинхронности всего тут тоже это вызываем
      this.createTimeSlots();

    },

    /* Почти хардкодим слоты времени дня, потому что
       * такую сущность на бэке решили не делать (на будущее)
       */
    createTimeSlots() {
      this.timeSlots = []; // Обнуляем, чтобы слоты не дублировались

      let date_from = '';
      let date_to = '';
      let minDays = this.eventDays.length || 1;
      let slotObj = {};

      for (let day = 0; day < minDays; day++) {
        for (let i = 0; i < 24; i++) {
          if (i < 9 || i > 21) {
            continue;
          }
          if (this.eventDays.length) {
            // n:00 — n:30
            date_from = this.$moment.utc(this.eventInfo.date_start).add(day, 'days').hours(i).minutes(0);
            date_to = this.$moment.utc(this.eventInfo.date_start).add(day, 'days').hours(i).minutes(30);
            let date_from_string = date_from.toDate().toISOString().replace('.000', '');
            slotObj = {
              date_start: date_from_string,
              date_end: date_to.toDate().toISOString().replace('.000', ''),
              meeting: this.findMeetingForTime(date_from_string)
            };
            this.timeSlots.push(slotObj);

            // n:30 — n+1:00
            date_from.hours(i).minutes(30);
            date_to.hours(i + 1).minutes(0);
            date_from_string = date_from.toDate().toISOString().replace('.000', '');
            slotObj = {
              date_start: date_from_string,
              date_end: date_to.toDate().toISOString().replace('.000', ''),
              meeting: this.findMeetingForTime(date_from_string)
            };
            this.timeSlots.push(slotObj);

          }
        }
      }

    },

    /* Returns null or a confirmed meeting
       */
    findMeetingForTime(timeSlot_date_start) {
      let result = null;
      for (let i = 0; i < this.confirmedRequests.length; i++) {
        if (timeSlot_date_start === this.confirmedRequests[i].date_start) {
          result = this.confirmedRequests[i];
          break;
        }
      }
      return result;
    },

    /* Calls the action to populate
       * the page with data
       */
    getMeetingsPageData() {

      if (!this.userInfo.id) {
        setTimeout(this.getMeetingsPageData, 150);
        return;
      }

      this.$store.dispatch('meetingsStore/getMeetingsList', {
        event_id: this.$route.params.eventId,
        user_id: this.userInfo.id
      });

    },

    /* Returns a boolean
       * about time slot availability
       */
    isTimeSlotAvailable(timeSlot) {
      return !timeSlot.meeting;
    },

    /* Makes a time slot available
       * — we are doing in by canceling a meeting with oneself
       */
    async markSlotAvailable(timeSlot) {
      if (timeSlot.meeting && timeSlot.meeting.id) {
        await this.$store.dispatch('meetingsStore/cancelMeeting', {
          event_id: this.$route.params.eventId,
          meeting_id: timeSlot.meeting.id,
        });
        timeSlot.meeting = null;
      }
    },

    /* Makes a time slot Not available
       * — we are doing in by scheduling a meeting with oneself
       */
    async markSlotNotAvailable(timeSlot) {
      let response = await this.$store.dispatch('meetingsStore/requestMeeting', {
        event_id: this.$route.params.eventId,
        user_id: this.userInfo.id,
        date_start: timeSlot.date_start.replace(':00Z', ''),
        date_end: this.$moment.utc(timeSlot.date_end).subtract(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
      });
      if (response && response.data && response.data.status === 'confirmed') {
        this.confirmedRequests.push(response.data);
        timeSlot.meeting = response.data;
      }
    },

    toggleContactFavorite(contact) {

      // state of is_favorite comes into this method BEFORE being changed
      if (contact.is_favorite === false) {
        let payload = {
          event_id: this.$route.params.eventId,
          contact_id: contact.id,
        };

        this.$store.dispatch('contactStore/addFavContact', payload).then(() => {
          contact.is_favorite = true;
        })
      } else {
        let payload = {
          event_id: this.$route.params.eventId,
          contact_id: contact.id,
        };

        this.$store.dispatch('contactStore/removeFavContact', payload).then(() => {
          contact.is_favorite = false;
        })
      }
    },


    /* Обработчик клика по иконке «написать сообщение»
       */
    messageContact(meeting) {
      const targetRouteName = 'promo-page-calendar-contact';
      const contactId = meeting.contact.id;

      // avoiding redundant navigation
      if (this.$route.name === targetRouteName && this.$route.params.contact_id == contactId) {
        return;
      }

      this.$router.push({
        name: targetRouteName,
        params: {
          contact_id: contactId
        },
      }).catch(() => {
      })
    }
  }

}
</script>

<style scoped lang="scss">
  .meetings-page {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10rem;

    .column {
      position: relative;
      background-color: #fff;
      border-radius: 25px;
      margin: 0 8px;
      width: calc(33% - 8px - 8px);
      padding: 10px 46px 10px 62px;
    }

    /* column names, AW-393 */
    .column-name {
      position: absolute;
      bottom: 100%;
      left: 10%;
      width: 100%;
      max-width: 80%;
      font-size: 1.6rem;
      font-weight: 300;
      text-align: center;
    }

    .column-meetings-userinfo {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
    }
  }


  .meetings-date-selector,
  .time-slot {
    border-color: rgba(112, 112, 112, 0.1);
  }

  .meetings-date-selector {
    border-style: solid;
    border-width: 0 0 1px;
    border-color: rgba(112, 112, 112, 0.1);
    padding: 27px 0 4px;
    text-align: center;


    .day {
      margin: 0 10px;
      display: inline-block;
      font-weight: 300;
      font-size: 1.2rem;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      color: #000;

      .badge-notification {
        position: absolute;
        bottom: 90%;
        left: 96%;
        width: 6px;
        height: 6px;
        min-width: 6px;
        padding: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 105%;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.5s;
        background-color: #00b6f8;
      }

      &-hover,
      &-selected {
        color: #00b6f8;

        &::after {
          width: 100%;
        }

      }
    }
  }

  .time-slot {
    padding: 6px 0;
    border-style: solid;
    border-width: 0 0 1px;
    position: relative;
    transition: opacity 0.25s, padding 0.25s, height 0.25s;

    &-overlay {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 3;

      .meeting-canceled {
        color: #d00;
        font-size: 1.3rem;
      }
    }

    &-fading {
      opacity: 0;
      pointer-events: none;
    }

    &-folding {
      height: 0;
      padding: 0;
    }

    &:last-child {
      border-width: 0;
    }
  }

  .time-slot-processing {
    opacity: 0.5;
    pointer-events: none;
    background: -webkit-gradient(linear, right top, left top, from(rgba(0, 10, 10, 0.3)), color-stop(rgba(0, 10, 10, 0)), to(rgba(0, 10, 10, 0.3)));
    background: linear-gradient(270deg, rgba(0, 10, 10, 0) 0%, rgba(0, 10, 10, 0) 40%, rgba(0, 10, 10, 0.1) 48%, rgba(0, 10, 10, 0.1) 51%, rgba(0, 10, 10, 0) 58%);
    background-size: auto;
    background-size: 250% 150%;
    animation: timeslotprocessinggradientanim 5s ease infinite;
  }

  .time-slot-content {
    margin-left: -36px;
    margin-right: -21px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .time-range {
      width: 56px;
      font-weight: bold;
      text-align: left;
    }

    .time-slot-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .title {
        font-size: 12px;
        font-weight: bold;
      }

      .subtitle {
        font-size: 11px;
      }
    }

    .time-slot-avatar-wrap {
      width: 70px;
      min-height: 1px;

      .avatar {
        background-color: #ccc;
        width: 60px;
        height: 60px;
        border-radius: 99px;
        background-size: cover;
      }

      .avatar-no-image {
        background-image: url("../../../../assets/images/no-avatar-300x300.png");
      }
    }

    .time-slot-contact,
    .time-slot-no-contact {
      overflow: hidden;
      text-overflow: ellipsis;
    }


    .time-slot-actions {
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .time-slot-action {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin: 0 6px;
      cursor: pointer;

      &:hover {
        opacity: 0.65;
      }

      &-disabled {
        opacity: 0.1;
        pointer-events: none;
      }
    }

    .time-slot-action-cancel,
    .time-slot-action-reject {
      width: 13px;
      height: 13px;
      background-image: url("../../../../assets/images/icons/icon-ionic-md-close.png");
    }

    .time-slot-action-message {
      width: 15px;
      height: 16px;
      background-image: url("../../../../assets/images/icons/icon-meeting-message.png");
    }

    .time-slot-action-handshake {
      width: 22px;
      height: 16px;
      background-image: url("../../../../assets/images/icons/icon-meeting-handshake.png");
    }

    .time-slot-action-markfree {
      width: 21px;
      height: 20px;
      margin: 0;
      background-image: url("../../../../assets/images/icons/icon-meeting-markfree.png");
    }

    .time-slot-action-markbusy {
      width: 21px;
      height: 22px;
      margin: 0;
      background-image: url("../../../../assets/images/icons/icon-ionic-md-close.png");
    }

    .time-slot-action-approve {
      width: 13px;
      height: 10px;
      background-image: url("../../../../assets/images/icons/icon-meeting-accept.png");
    }

    .time-slot-action-reject {
      width: 10px;
      height: 10px;
    }

    .time-slot-action-fav {
      width: 13px;
      height: 12px;
      margin-top: -5px;

      svg {
        max-width: 100%;
        height: auto;
      }
    }

    .time-slot-action-share {
      width: 16px;
      height: 16px;
      position: relative;
      overflow: visible;

      &:hover {
        opacity: 1;

        .icon {
          opacity: 0.65;
        }
      }
    }

  }

  /* hide unneeded buttons depending on slot availability */
  .time-slot-available .time-slot-action-markfree,
  .time-slot-unavailable .time-slot-action-markbusy {
    display: none;
  }

  /* color of available | not available */
  .time-slot-unavailable .time-slot-no-contact {
    color: rgba(131, 0, 0, 1);
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
  }

  .time-slot-available .time-slot-no-contact {
    color: rgba(0, 96, 0, 1);
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
  }

  .no-meetings-in-day {
    padding: 32px 10px;
    text-align: center;
    margin: 0 -20px 0 -36px;
    background-color: #f0f8ff;
  }

  /* transition for time slots changed by .meetings-date-selector */
  .time-slots-fade-enter-active,
  .time-slots-fade-leave-active {
    transition: opacity 0.25s;
  }

  .time-slots-fade-enter,
  .time-slots-fade-leave-to { /* .fade-leave-active below version 2.1.8 */
    opacity: 0;
  }

  .meeting-error {
    position: absolute;
    width: calc(100% + 50px);
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
    border-color: #d88;
    background-color: #fee;
    font-size: 1.2rem;

    .meeting-error-actions {
      font-variant: small-caps;
      font-size: 1.1rem;
      font-weight: 300;
      padding-top: 1rem;
      letter-spacing: 0.1em;
    }

    .meeting-error-action:hover {
      opacity: 0.5;
      display: inline-block;
      margin: 0 1em;
    }
  }

  .demo-circular-progress {
    left: calc(50% - 16px);
  }
</style>
